import Lightning from "../lightning";


const Hero_8 = ({lnAuth}) => {
  console.log('lnAuth', lnAuth)
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative py-20 md:pt-5">

      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
        <img src="/images/gradient.jpg" alt="gradient" />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img src="/images/gradient_dark.jpg" alt="gradient dark" />
      </picture>

        <div className="container">
          <div className="mx-auto max-w-2xl pt-5 md:pt-20 text-center">
            <p className="mx-auto mb-10 max-w-m text-[1rem] lg:text-lg leading-normal dark:text-jacarta-50">
            로그인을 위해 라이트닝 월렛으로 QR코드를 스캔하세요.
            </p>
            <Lightning lnurl={lnAuth ? lnAuth.lnurl : undefined} />
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_8;
