import { useState, useEffect } from "react";
import {signIn } from "next-auth/react";
import { useSession } from "next-auth/react";
import Lightning from "../../components/lightning";
import Meta from "../../components/Meta";
import HOME from "../../components/login/home";


const ping = async (k1, callbackUrl) => {
  const response = await fetch("/api/lnurl/ping", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ k1 }),
    cache: "default",
  });

  const data = await response.json();
  if (!data.pubkey) return;

  await signIn("lightning", {
    pubkey: data.pubkey,
    k1,
    callbackUrl,
  });
};

export default function AuthPage({ providers }) {
  const session = useSession();
  const [lnAuth, setAuth] = useState();

  async function create() {
    const response = await fetch("/api/lnurl/create");
    const data = await response.json();
    setAuth({
      lnurl: data.lnurl,
      k1: data.k1,
      callbackUrl: process.env.NEXT_PUBLIC_SITE_URL,
    });
  }

  // create the lnurl
  useEffect(() => {
    create();
  }, []);

  // poll to check for updated auth state
  useEffect(() => {
    if (
      !lnAuth ||
      !lnAuth.k1 ||
      !lnAuth.callbackUrl ||
      (session && session.data)
    )
      return;

    const intervalId = setInterval(
      () => ping(lnAuth.k1, lnAuth.callbackUrl),
      1000
    );

    return () => clearInterval(intervalId);
  }, [lnAuth, session]);

  // reset the QR code ever 2 minutes
  useEffect(() => {
    if (!lnAuth || !lnAuth.lnurl) return;

    let timer = setTimeout(() => create(), 2 * 60 * 1000);

    return () => clearTimeout(timer);
  }, [lnAuth]);

  return (
    <>

    <Meta title="야나부닷컴" 
    desc={"야나부, 비트코인, lightning network"} keyword={"비트코인, p2p, lightning network"}
    />
      {<HOME lnAuth={lnAuth}/>}
    </>
  );
}
