import { useEffect, useState } from "react";
import Image from "next/image";
// import QRCode from "qrcode";
import { QRCode } from 'react-qrcode-logo';

export default function Lightning({ lnurl }) {
  const [qrCode, setQrCode] = useState();

  const [invoice, setInvoice] = useState("");
  const [copyMsg, setCopyMsg] = useState("invoice 복사하기");



  async function copyTextToClipboard(text) {

    let text_ = ""
    if(text.includes("lightning:")) text_ = text.split("lightning:")[1]
    else text_ = text
    // setOpen(true);
    setCopyMsg("복사 됐습니다.")
    setTimeout(()=>{
      setCopyMsg("invoice 복사하기")
    },1000);
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text_);
    } else {
      return document.execCommand('copy', true, text_);
    }
  }

  useEffect(() => {
    (async () => {
      if (lnurl) {
        console.log('lnurl', lnurl)
        setQrCode(lnurl)
      } else {
      }
    })();
  }, [lnurl]);

  if (!qrCode) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <a onClick={()=>{copyTextToClipboard(qrCode)}}>
      <h3 className="font-display text-jacarta-700 py-2 text-center text-1xl font-medium dark:text-white">
      {copyMsg}
      </h3>            
      </a>

      <div className="text-center" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
      <a onClick={()=>{copyTextToClipboard(qrCode)}}>
      <QRCode
        ecLevel="H"
        value={qrCode}
        size={300}
        logoImage="images/trading/bitcoin.png"
        logoWidth={50}
        />
      </a>
      </div>
  

    </div>
  );
}
